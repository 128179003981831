import { handleClassNames } from "lib/cssHelpers";
import React from "react";
import Style from "@assets/css/home.module.css";
import Slider from "react-slick";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { getTopSellingJob } from "redux/actions/apiActions";
import {
  setCarPopup,
  setLoader,
  toggleChooseCar,
} from "redux/actions/globalAction";
import { useRouter } from "next/router";
import { gtmTopSellingJobs } from "lib/google-tag-manager";
import ChooseCar from "components/Header/ChooseCar";
import ChooseCarMobile from "components/common/ChooseCarMobile";
import { isMobile } from "react-device-detect";

const TpSelling = ({
  globalData,
  apiData,
  getTopSellingJob,
  setLoader,
  toggleChooseCar,
  setCarPopup,
}) => {
  const handleClasses = (classValue) => {
    return handleClassNames(classValue, Style);
  };
  const router = useRouter();
  const [data, setData] = useState([]);
  const [showCarPopUp, setShowCarPopUp] = useState(false);

  useEffect(() => {
    setData(apiData.topSelling);
  }, [apiData.topSelling]);

  useEffect(() => {
    getTopSellingJob(globalData?.carData?.modelId);
  }, [globalData?.carData?.modelId]);

  const handleTopSelling = (e) => {
    gtmTopSellingJobs(e.Name);
    setLoader(true);
    router.push(
      `/services/${globalData.locationData?.data?.EmirateAreaSlug || "dubai"}/${
        e.Slug
      }`
    );
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className={`section-top-selling ${handleClasses("tp-selling")}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className={`text-center ${handleClasses("title-anim")}`}>
              <div
                className={`${handleClasses(
                  "header-all"
                )} heading-common-font-size`}
              >
                Top Selling Jobs
              </div>
            </div>
            <p className={`text-center ${handleClasses(" subHead-all")} mb-0`}>
              {!isMobile ? (
                <ChooseCar isopen={true} isLogin={true} />
              ) : (
                <ChooseCarMobile
                  show={showCarPopUp}
                  setShow={setShowCarPopUp}
                />
              )}
              {!globalData.carData?.brandId ? (
                <p className="text-center mb-0 pb-0">
                  <a
                    style={{ paddingBottom: "0px !important" }}
                    className="subHead-all text-primary sub-heading-common-font-size mb-0 pb-0"
                    onClick={() => {
                      if (!isMobile) {
                        setCarPopup("brand");
                      }
                      toggleChooseCar(true);
                      setShowCarPopUp(!showCarPopUp);
                    }}
                  >
                    Select your car to <u>view prices</u>
                  </a>
                </p>
              ) : (
                <p
                  style={{ paddingBottom: "0px !important" }}
                  className={`text-center sub-heading-common-font-size ${handleClasses(
                    " subHead-all"
                  )} pb-0 mb-0`}
                >
                  Top jobs that other <b>{globalData.carData?.brand}</b> owners
                  are buying
                </p>
              )}
            </p>
          </div>
        </div>
        <div className={`${handleClasses("tpselling-slider")} custom-adjust-tpselling`}>
          <div className={handleClasses("tpslide-box")}>
            <Slider {...settings} className="tpselling-slider-custom">
              {data.map((e) => {
                return (
                  <div
                    className={handleClasses("outerskin-block")}
                    data-aos="fade-right"
                  >
                    <div className={handleClasses("top-selling-card-skin")}>
                      <a className="w-100" onClick={() => handleTopSelling(e)}>
                        <div className={handleClasses("top-selling-card")}>
                          <div
                            className={handleClasses("top-selling-container")}
                          >
                            <div
                              className={handleClasses(
                                "top-selling-image-container"
                              )}
                            >
                              <div className={handleClasses("img top-selling")}>
                                <div className={handleClasses("skimage-block")}>
                                  <img src={e.ImgUrl} alt="ob-img" />
                                </div>
                              </div>
                              <div className={handleClasses("overlay")} />
                              {e.Offer ? (
                                <div
                                  className="product-discount custom-product-discount"
                                  title={"Offer Available"}
                                >
                                  <img
                                    src="/assets/discount.svg"
                                    alt="discount"
                                  />
                                  Offer Available
                                </div>
                              ) : null}
                              <div
                                className={handleClasses("top-selling-details")}
                              >
                                <div className={handleClasses("top-sell-text")}>
                                  {e.Name}
                                </div>
                                {globalData?.carData?.modelId ? (
                                  <div
                                    className={handleClasses(
                                      "top-sell-text-yl"
                                    )}
                                  >
                                    AED {e.Cost}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
    globalData: state.globalData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTopSellingJob: (mId) => {
      dispatch(getTopSellingJob(mId));
    },
    setCarPopup: (flag) => {
      dispatch(setCarPopup(flag));
    },
    setLoader: (flag) => {
      dispatch(setLoader(flag));
    },
    toggleChooseCar: (flag) => {
      dispatch(toggleChooseCar(flag));
    },
    setCarPopup: (flag) => {
      dispatch(setCarPopup(flag));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TpSelling);