import { handleClassNames } from "lib/cssHelpers";
import React from "react";
import styles from "@assets/css/home.module.css";
import Slider from "react-slick";
import { useRouter } from "next/router";
import { connect, useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { fetchBrandObServe } from "actions/homePage";
import Select from "react-select";
import { FaArrowRight } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import { setLoader } from "redux/actions/globalAction";
import { AiOutlineArrowRight } from "react-icons/ai";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const BrandsSection = ({ apiData, globalData, setLoader }) => {
  const handleClasses = (classValue) => {
    return handleClassNames(classValue, styles);
  };
  const [brandList1, setBrandList1] = useState([]);
  const [brandList2, setBrandList2] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [showCarSelect, setShowCarSelect] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const router = useRouter();

  useEffect(() => {
    dispatch(fetchBrandObServe());
  }, []);

  useEffect(() => {
    var half_length = Math.ceil(apiData.homeBrands.length / 2);
    var leftSide = apiData.homeBrands.slice(0, half_length);
    var rightSide = apiData.homeBrands.slice(half_length);
    setBrandList1(rightSide);
    setBrandList2(leftSide);
    let cars = apiData.brands.map((car, index) => {
      return {
        label: car.Name,
        value: car.Slug,
        data: car,
      };
    });
    setIsLoading(false);
    setList(cars);
  }, [apiData.homeBrands, apiData.brands]);

  var settings = {
    dots: false,
    // arrow:true,
    infinite: true,
    // speed: 1200,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    lazyload: true,
    cssEase: "linear",
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        dots: true,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const handleBrandChange = (e) => {
    setSelectedBrand(e);
  };

  return (
    <section className={`sm-brand-slider ${handleClasses("brands-section")}`}>
      <img
        src="assets/home/bubble.webp"
        alt="bubble"
        className={handleClasses("bubble-img")}
      />
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div
              className={`${handleClasses(
                "header-all"
              )} heading-common-font-size text-center-mobile`}
            >
              Car Brands We Serve
            </div>
            <p
              className={`text-start text-primary ${handleClasses(
                "subHead-all"
              )} sub-heading-common-font-size`}
            >
              Our multi-branded services are powered by not just specialist
              partner workshops but also OB’s own expert service advisors
            </p>
          </div>
          <div className="col-md-4">
            <div
              className={
                isMobile
                  ? "d-flex align-items-end justify-content-end"
                  : "d-flex align-items-end justify-content-start W-100"
              }
            >
              <Select
                className="basic-single"
                classNamePrefix="select"
                isLoading={isLoading}
                isClearable
                isRtl={false}
                isSearchable
                options={list}
                placeholder="Search Brand"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: 200,
                  }),
                }}
                onChange={handleBrandChange}
              />
              <button
                className="border-none bg-yellow ml-1 rounded px-2"
                style={{ height: 38, opacity: selectedBrand == null ? 0.6 : 1 }}
                disabled={selectedBrand == null}
                onClick={() => {
                  setLoader(true);
                  localStorage.setItem(
                    "global_car_data",
                    JSON.stringify(selectedBrand?.data)
                  );
                  router.push(
                    `/services/${
                      globalData.locationData?.data?.EmirateAreaSlug || "dubai"
                    }/oil-change/${selectedBrand.value}`
                  );
                }}
              >
                <FaArrowRight />
              </button>
            </div>
          </div>
        </div>
        <div className={handleClasses("brand-scroll-first")}>
          <div className={handleClasses("br-lg-inner")}>
            <Slider {...settings}>
              {brandList1.map((item) => {
                return (
                  <div className={handleClasses("br-logo-outer")}>
                    <div className={handleClasses("brand-logo")}>
                      <div className={handleClasses("brand-logo-fs")}>
                        <div className={handleClasses("brand-logo-sc")}>
                          <img alt="Brand" src={item.ImgUrl} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
        <div
          className="p-0 m-0 show-section-mobile margin-top-for-request-page"
        >
          <p className="p-0 m-0 d-flex justify-content-between align-items-center px-3">
            <BiChevronLeft size={25} />
            <BiChevronRight size={25} />
          </p>
        </div>
        <div
          className={`${handleClasses("brand-scroll-first") } margin-top-for-request-page`}
        >
          <div className={handleClasses("br-lg-inner")}>
            <Slider {...settings}>
              {brandList2.map((item) => {
                return (
                  <div className={handleClasses("br-logo-outer")}>
                    <div className={handleClasses("brand-logo")}>
                      <div className={handleClasses("brand-logo-fs")}>
                        <div className={handleClasses("brand-logo-sc")}>
                          <img alt="Brand" src={item.ImgUrl} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
    globalData: state.globalData,
    profile: state.profile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: (flag) => {
      dispatch(setLoader(flag));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandsSection);