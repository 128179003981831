import React from "react";
import styles from "@assets/css/home.module.css";
import { handleClassNames } from "lib/cssHelpers";
import { useState } from "react";
import { isMobile } from "react-device-detect";

const FaqMapItem = ({ item, show, setShow }) => {


  const handleClasses = (classValue) => {
    return handleClassNames(classValue, styles);
  };

  return (
    <div
      className={handleClasses("faqs-inner-block")}
      style={isMobile ? { margin: "10px 0" } : {}}
    >
      <div
        className={handleClasses(
          show == item.id ? "heading-txt-acc" : "heading-txt-acc collapsed"
        )}
        id="headingOne"
        data-toggle="collapse"
        data-target="#pv1"
        aria-expanded="true"
        aria-controls="pv1"
      >
        <h5 className="mb-0">
          <span
            className={`btn ${handleClasses("btn-link")}`}
            style={{ fontSize: "15px" }}
          >
            {item.question}
          </span>
          <span
            onClick={() => setShow(show === item.id ? false : item.id)}
            className={handleClasses("accicon")}
          >
            <i
              className="fas fa-angle-down rotate-icon"
              style={show == item.id ? { transform: "rotate(180deg)" } : {}}
            />
          </span>
        </h5>
      </div>
      {show == item.id ? (
        <div
          id="pv1"
          className="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div className={handleClasses("card-body para-text")}>
            {/* <p className="text-white">{item.answer}</p> */}
            <p className="text-white" dangerouslySetInnerHTML={{ __html: item.answer }}></p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FaqMapItem;