import { handleClassNames } from "lib/cssHelpers";
import React from "react";
import Style from "@assets/css/home.module.css";
import Styles from "@assets/css/header.module.css";
import { useEffect } from "react";
import { useState } from "react";
import Slider from "react-slick";
import { setLoader } from "redux/actions/globalAction";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import { fetchHomePageBanner } from "redux/actions/apiActions";
import { category } from "lib/data/category";

const Sliderblock = ({
  apiData,
  handleShowSos,
  setLoader,
  globalData,
  fetchHomePageBanner,
}) => {
  const router = useRouter();
  const settings = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    arrows: true,
    autoplaySpeed: 2000,
  };

  const [categories, setCategories] = useState([]);

  const handleClasses = (classValue) => {
    return handleClassNames(classValue, Style);
  };
  const handleClass = (classValue) => {
    return handleClassNames(classValue, Styles);
  };

  const handleBannerClick = (item) => {
    if (item?.RedirectionUrl) {
      setLoader(true);
      router.push(item.RedirectionUrl);
    }
    // else {
    //   setLoader(false);
    //   toast.dismiss();
    //   toast.error("Something went wrong! Please retry.");
    // }
  };

  useEffect(() => {
    console.log("sjkldflksjdfsjkldflsjkdflsjkdfsldkjf", category);
    setCategories(category.category);
    fetchHomePageBanner("HOMEBANNER");
  }, [apiData.categories]);

  const handleCategory = (item) => {
    setLoader(true);
    localStorage.setItem("selected-service", item.Slug);
    localStorage.setItem("selected-service-title", item.Name);
    router.push(
      `/services/${globalData.locationData?.data?.EmirateAreaSlug || "dubai"}/${
        item.Slug
      }`
    );
  };

  return (
    <section className={handleClasses("sliderblock")}>
      <div className="container-fluid custom-width-banner">
        <div className="row">
          <div
            className={`col-md-6 col-8 ${handleClasses("ovelapped-div-hm")}`}
          >
            <div className={handleClasses("head-sr-block")}>
              <h1 className="main-new-heading">
                What <span style={{ color: "#0451be" }}>Service</span> are you{" "}
                <br />
                <h1 className="main-new-heading mt-2 mb-3">looking for?</h1>
              </h1>
            </div>
            <div className={handleClasses("service-block-home")}>
              <ul className={handleClass("style-ul")}>
                {categories && categories.length
                  ? categories.map((item) => {
                      if (item.Name !== "New Tyres") {
                        return (
                          <li
                            onClick={() => {
                              sessionStorage.removeItem("isWorkshopCollection");
                              sessionStorage.removeItem("WORKSHOP_JOURNEY");
                              handleCategory(item);
                            }}
                          >
                            <a href="javascript:void(0)">
                              <span>
                                <img
                                  width={100}
                                  src={`${item.ImgUrl}`}
                                  alt="ImgUrl"
                                />
                              </span>
                              <strong>{item.Name}</strong>
                            </a>
                          </li>
                        );
                      } else {
                        // Exclude the item with the name "Service Contract"
                        return null;
                      }
                    })
                  : null}
                <li
                  onClick={() => handleShowSos()}
                  className={handleClasses("reqcall")}
                >
                  <a href="javascript:void(0)">
                    <span>
                      <img
                        src="/assets/service-icon/request-old.webp"
                        alt="request"
                        style={{ marginTop: "3px", width: "50px" }}
                      />
                    </span>
                    <strong>Request Callback</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={`offset-md-4 col-md-8 ${handleClasses("slide-hm")}`}>
            <div className={handleClasses("slider-block")}>
              <Slider className="slider-main" {...settings}>
                {apiData.homePageBanner?.length &&
                  apiData?.homePageBanner?.map((item, index) => (
                    <div
                      className={handleClasses("slider-image")}
                      style={{
                        cursor: "pointer",
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                      key={index}
                    >
                      <img
                        onClick={() => handleBannerClick(item)}
                        alt={item?.AltText}
                        loading="lazy"
                        src={item?.ImageUrl}
                      />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
    globalData: state.globalData,
    profile: state.profile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: (flag) => {
      dispatch(setLoader(flag));
    },
    fetchHomePageBanner: (flag) => {
      dispatch(fetchHomePageBanner(flag));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sliderblock);
