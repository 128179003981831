import { handleClassNames } from "lib/cssHelpers";
import React from "react";
import Style from "@assets/css/home.module.css";
import { googleReviews } from "../../lib/reviews";
import Swiper from "react-id-swiper";
import LazyLoad from "react-lazyload";
import ReadMoreReact from "read-more-react/dist/components/ReadMoreReact";
import { useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import Slider from "react-slick";

const ReviewSection = () => {
  const handleClasses = (classValue) => {
    return handleClassNames(classValue, Style);
  };

  const params = {
    dots: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const params11 = {
    dots: true,
    infinite: false,
    loop:true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 76,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };



  return (
    <section className={handleClasses("review-section")}>
      <div className="container">
        <img
          className={handleClasses("google-icon")}
          src="assets/home/google.webp"
          alt="google"
        />
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center title-anim">
              <div className={`${handleClasses("header-all")} heading-common-font-size`}>
                Customer Reviews
              </div>
            </div>
            <div className="text-center sub-heading-common-font-size">
              <div className={`${handleClasses("rating-blk")} start-alignment`}>
                <span className="text-secondary sub-heading-common-font-size">Rating: 4.4</span>
                <div
                  className={`star-skn-block ${handleClasses(
                    "star-skn-block"
                  )}`}
                >
                  <div className={handleClasses("stars")}>
                    <svg viewBox="0 0 576 512" width={100} title="star">
                      <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" />
                    </svg>
                    <svg viewBox="0 0 576 512" width={100} title="star">
                      <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" />
                    </svg>
                    <svg viewBox="0 0 576 512" width={100} title="star">
                      <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" />
                    </svg>
                    <svg viewBox="0 0 576 512" width={100} title="star">
                      <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" />
                    </svg>
                    <svg viewBox="0 0 576 512" width={100} title="star">
                      <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" />
                    </svg>
                    <div
                      className={handleClasses("cover")}
                      style={{ width: "10%" }}
                    />
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://g.page/r/CQ-cn6YSSqO2EB0/review"
                  className={`${handleClasses("purple")} sub-heading-common-font-size`}
                >
                  Google Reviews
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={handleClasses("rev-slider-skin")}>
          <div className={handleClasses("rev-slider-container")}>
            <Slider {...params} className="review-section-home-slider">
              {googleReviews?.map((e, index) => (
                <LazyLoad key={index}>
                  <div
                    className={`d-flex justify-content-center align-items-center flex-column mt-5 ${handleClasses(
                      "google-head"
                    )}`}
                  >
                    <div className={handleClasses("google-card")}>
                      <div className={handleClasses("google-container")}>
                        <div className="pl-3">
                          <div className={handleClasses("google-text")}>
                            <div className="display-text-group">
                              <span className={handleClasses("displayed-text custom-text-review")}>
                                <ReadMoreReact
                                  text={e.content}
                                  min={80}
                                  readMoreText={
                                    <a className="text-primary">Read More</a>
                                  }
                                />
                                {/* I was skeptical at first but since they are partnered
                          with Amazon it gave me a bit of confidence to avail
                          services from Open Bonnet.
                          <span className={handleClasses("hide")}>
                            They will assist you with the process and are highly
                            attentive to your queries and concerns. They help
                            their customers understand the sequences and does
                            the job nicely with real-time support. Highly
                            recommended car service and most specially available
                            through Amazon.
                          </span>
                          <div className={handleClasses("read-more-button")}>
                            <a className="text-primary">Read More</a>
                          </div> */}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-start pl-3">
                          <div className={handleClasses("profile")}>
                            <div className={handleClasses("pro-image")}>
                              <img
                                src={"/assets/reviews/" + e.img}
                                alt="Google"
                                decoding="async"
                                data-nimg="fill"
                              />
                            </div>
                          </div>
                          <p
                            className={`p-2 ${handleClasses(
                              "google-text pro-info"
                            )}`}
                          >
                            {" "}
                            {e.name}, <br />
                            <span className={handleClasses("pro-info-span")}>
                              {e.car}, {e.location}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </LazyLoad>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReviewSection;
