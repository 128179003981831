import { handleClassNames } from "lib/cssHelpers";
import React from "react";
import Style from "@assets/css/home.module.css";
import { useRouter } from "next/router";
import {
  setCarPopup,
  setHideHeader,
  setLoader,
  toggleChooseCar,
} from "redux/actions/globalAction";
import { connect, useDispatch } from "react-redux";
import CustomPlan from "components/Homepage/CustomPlan";
import { useState } from "react";
import ChooseCar from "components/Header/ChooseCar";
import { isMobile } from "react-device-detect";
import ChooseCarMobile from "components/common/ChooseCarMobile";
import { useEffect } from "react";
import { fetchLowestPackage } from "actions/homePage";
import { FaHammer, FaToolbox } from "react-icons/fa";
import { FaGears, FaScrewdriverWrench } from "react-icons/fa6";

const OfferSection = ({
  setLoader,
  globalData,
  apiData,
  toggleChooseCar,
  setCarPopup,
  setHideHeader,
}) => {
  const router = useRouter();
  const [showCarPopUp, setShowCarPopUp] = useState(false);
  const dispatch = useDispatch();

  const handleClasses = (classValue) => {
    return handleClassNames(classValue, Style);
  };

  useEffect(() => {
    if (globalData.locationData?.data) {
      dispatch(
        fetchLowestPackage(
          globalData.locationData?.data.EmirateId,
          globalData.locationData?.data.EmirateAreaId,
          globalData.carData?.brandId || null,
          globalData.carData?.modelId || null
        )
      );
    }
  }, [globalData.carData]);

  const buyNow = () => {
    setLoader(true);
    router.push("/services/dubai-dubai-silicon-oasis/service-contract");
  };

  const [showCustomPlan, setShowCustomPlan] = useState(false);

  const handleRequest = () => {
    setLoader(true);
    router.push(
      `/services/${globalData.locationData?.data?.EmirateAreaSlug || "dubai"
      }/mobile-mechanic`
    );
  };

  return (
    <>
      <section className="new-ob-offer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div
                className={
                  globalData.carData?.brandId
                    ? "img-banner-block"
                    : "img-banner-block empty-brand"
                }
                style={
                  isMobile ? { paddingBottom: "2px", paddingTop: "2px" } : {}
                }
              >
                <div className="tyre-icon-annual">
                  <img src="/assets/home/tyre-icon.webp" />
                </div>
                <div
                  className="title-bar-annual desktop-element"
                  style={isMobile ? {} : { paddingTop: "5px" }}
                >
                  <h2 style={{ color: "#5A5A5A" }}>
                    Annual Service Contract
                  </h2>
                  {globalData.carData?.brandId ? (
                    <div className="d-flex">
                      <h3>
                        For {globalData.carData?.brand}{" "}
                        {globalData.carData?.modal}{" "}
                      </h3>
                      {!isMobile && apiData?.lowestPackages?.MinCost ? (
                        <div
                          className="title-bar-annual desktop-element"
                          style={{
                            paddingBottom: "0px",
                            paddingTop: "0px",
                            marginTop: '-7px'
                          }}
                        >
                          <label>
                            <span>AED</span>
                            <strong>{apiData?.lowestPackages?.MinCost}</strong>
                          </label>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <div className="d-flex">
                  <div
                    className="d-flex flex-column"
                    style={{
                      justifyContent: "space-evenly",
                      ...(isMobile ? { marginTop: "-20px !important" } : {}),
                    }}
                  >
                    {isMobile &&
                      globalData.carData?.brandId &&
                      apiData?.lowestPackages?.MinCost ? (
                      <div
                        className="title-bar-annual desktop-element"
                        style={{
                          paddingBottom: "0px",
                          paddingTop: "0px",
                          ...(isMobile
                            ? { marginTop: "-29px" }
                            : { marginTop: "-29px", marginBottom: "20px" }),
                        }}
                      >
                        <label>
                          <span>AED</span>
                          <strong>{apiData?.lowestPackages?.MinCost}</strong>
                        </label>
                      </div>
                    ) : null}
                    <button
                      onClick={() => {
                        if (globalData.carData?.brandId) {
                          buyNow();
                          return;
                        }
                        toggleChooseCar(false);
                        setShowCustomPlan(true);
                      }}
                      className="req-an-btn"
                      style={{
                        width: "160px",
                        ...(isMobile ? {} : { marginBottom: "10px" }),
                      }}
                    >
                      {globalData.carData?.brandId ? "Buy Now" : "Choose Car"}
                    </button>
                    <button
                      onClick={() => {
                        toggleChooseCar(false);
                        setShowCustomPlan(true);
                      }}
                      style={{ width: "160px" }}
                      className="btn-an-btn"
                    >
                      Request Plan
                    </button>
                  </div>
                  {isMobile ? (
                    <div>
                      <img src="/assets/home/tyre-icon.webp" />
                    </div>
                  ) : null}
                </div>

                <div
                  className="list-bar-annual"
                  style={!isMobile ? {} : { marginTop: "0px !important" }}
                >
                  <ul>
                    <li style={{ listStyle: "none ", marginBottom: "5px", }}>
                      <span style={{ display: "flex", alignItems: "center" }}> <FaToolbox style={{ marginRight: "15px", fontSize: "18px", }} /> <span style={{ fontSize: "14px" }}> One Country. One Price. Any Garage.</span> </span>
                    </li>
                    <li style={{ listStyle: "none ", marginBottom: "5px", }}>
                      <span style={{ display: "flex", alignItems: "center" }}> <FaScrewdriverWrench style={{ marginRight: "15px", fontSize: "18px" }} /><span style={{ fontSize: "14px" }}>  Buy 6 months or Annual Service Contracts.</span> </span>
                    </li>
                    <li style={{ listStyle: "none ", marginBottom: "5px" }}>
                      <span style={{ display: "flex", alignItems: "center" }}> <FaHammer style={{ marginRight: "15px", fontSize: "18px" }} /><span style={{ fontSize: "14px" }}> Get upto 70% lower price than dealerships.</span> </span>
                    </li>
                    <li style={{ listStyle: "none ", marginBottom: "5px" }}>
                      <span style={{ display: "flex", alignItems: "center" }}> <FaGears style={{ marginRight: "15px", fontSize: "18px" }} /><span style={{ fontSize: "14px" }}> Fully online redemption & service tracking. </span> </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {!isMobile && <div className="col-md-6 show-section-web">
              <div className="mechanic-container-custom">
                <div className="mob-mac-block">
                  <h2 className="heading-common-font-size main-new-heading">Mobile Mechanic</h2>
                  <p className="sub-heading-common-font-size">
                    Let Our Crew Reach Your Location For Selected Periodic
                    Services
                  </p>
                  <button
                    onClick={() => handleRequest()}
                    className="req-an-btn"
                  >
                    Request Now
                  </button>
                </div>
                <div className={globalData.carData?.brandId ? "mob-mac-img" : "mob-mac-img-not-selected"}>
                  <img src="/assets/home/mob-car.webp" height={globalData.carData?.brandId ? "172px" : '100%'} />
                </div>
              </div>
            </div>}
          </div>
        </div>

        {!isMobile ? (
          <ChooseCar isopen={true} isLogin={true} />
        ) : (
          <ChooseCarMobile show={showCarPopUp} setShow={setShowCarPopUp} />
        )}

        <CustomPlan
          show={showCustomPlan}
          handleClose={() => {
            localStorage.removeItem("customPlan");
            toggleChooseCar(false);
            setShowCustomPlan(false);
          }}
        />
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
    globalData: state.globalData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: (flag) => {
      dispatch(setLoader(flag));
    },
    setCarPopup: (flag) => {
      dispatch(setCarPopup(flag));
    },
    setLoader: (flag) => {
      dispatch(setLoader(flag));
    },
    toggleChooseCar: (flag) => {
      dispatch(toggleChooseCar(flag));
    },
    setHideHeader: (flag) => {
      dispatch(setHideHeader(flag));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferSection);