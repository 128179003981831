import { handleClassNames } from 'lib/cssHelpers';
import React from 'react'
import Style from '@assets/css/home.module.css'

const KtFeatured = () => {
    const handleClasses = (classValue) => {
        return handleClassNames(classValue, Style);
    }

    return(
        <section className={handleClasses("kt-featured")}>
                    <div className="container">
                        <div className="row">
                            <div className="col-4">
                                <div className={handleClasses("ob-highlight ")} data-aos="fade-up">
                                    <div className={handleClasses("ob-imo")}>
                                        <div className={handleClasses("inner-imo")}>
                                            <img src="assets/home/gear.webp" alt="gear"/>
                                        </div>
                                    </div>
                                    <div>
                                        <h3>400,000+</h3>
                                        <h5>Listed Job Prices</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div
                                    className={handleClasses("ob-highlight")}
                                    data-aos="fade-up"
                                    data-aos-duration={500}
                                >
                                    <div className={handleClasses("ob-imo")}>
                                        <div className={handleClasses("inner-imo")}>
                                            <img src="assets/home/happy.webp" alt="happy" />
                                        </div>
                                    </div>
                                    <div>
                                        <h3> 8,000+</h3>
                                        <h5>Vehicles Serviced </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div
                                    className={handleClasses("ob-highlight")}
                                    data-aos="fade-up"
                                    data-aos-duration={600}
                                >
                                    <div className={handleClasses("ob-imo")}>
                                        <div className={handleClasses("inner-imo")}>
                                            <img src="assets/home/hand.webp" alt="hand"/>
                                        </div>
                                    </div>
                                    <div>
                                        <h3>100+</h3>
                                        <h5>Key Partnerships</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    )
}

export default KtFeatured