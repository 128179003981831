import { handleClassNames } from "lib/cssHelpers";
import React from "react";
import Style from "@assets/css/home.module.css";
import Slider from "react-slick";
import { useRouter } from "next/router";
import LazyLoad from "react-lazyload";
import { isMobile } from "react-device-detect";

const images = [
  { url: "/assets/why/Caraousal-1.webp" },
  { url: "/assets/why/Caraousal-2.webp" },
  { url: "/assets/why/Caraousal-3.webp" },
];

const CardSlide = ({ setLoader }) => {
  const handleClasses = (classValue) => {
    return handleClassNames(classValue, Style);
  };

  const router = useRouter();

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipe: false,
    slidesToShow: 1,
  };

  return (
    <section className={handleClasses("card-slide-block hide-slide-mobile")}>
      <div className="container">
        <div className="row">
          <div className={`col-md-4 ${handleClasses("col-md-4")}`}>
            <div className={handleClasses("card-why")}>
              <div
                className="w-100 h-100 position-relative"
                style={{ cursor: "pointer" }}
              >
                <div className={handleClasses("why-crd-1-skin")}>
                  <div className={handleClasses("why-crd-2")}>
                    {!isMobile ? <img alt="super-car" src="assets/home/super-car-gray.webp" /> :
                      <img alt="super-car" src="assets/home/super-car-gray.webp" />}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setLoader(true);
                    router.push("/super-car");
                  }}
                  className={handleClasses("why-crd-title")}
                >
                  <h2 className="sptxt-bloxk"><span>Super</span> <span className="text-white">Cars</span></h2>
                  <p className="text-white">
                    Your Premium Car Needs Specialized Care & Trusted Advice
                  </p>
                  <p className="mb-0 text-white">
                    <span style={{ textDecoration: "underline" }}>View More</span> <i><img style={{ cursor: "pointer", width: "26px" }} src="assets/home/right-angle.webp" /> </i>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`col-md-4 ${handleClasses(
              "col-md-4 custom-slder-section-why"
            )}`}
          >
            <div className={handleClasses("card-why")}>
              <div className={`slider-why ${handleClasses("slider-why")}`}>
                <Slider {...settings}>
                  {images.map((item, index) => {
                    return <LazyLoad key={index}>
                      <div className={handleClasses("why-sl-image-block")}>
                        <div className={handleClasses("why-sl-img")}>
                          <img
                            loading="lazy"
                            src={item.url}
                            alt="why-sl-img"
                          />
                        </div>
                      </div>
                    </LazyLoad>
                  })}
                </Slider>
              </div>
              <div className={handleClasses("whob-txt")}>
                <h3 className="mt-2">Why <span style={{ color: '#0451be' }}>Open Bonnet?</span></h3>
              </div>
              <ul className={`m-3 ${handleClasses("why-text-sc why-ob-list-style-none")}`}>
                <li className={handleClasses("my-1")} style={{ marginBottom: "2px" }}>
                  <img width={20} style={{ marginBottom: "5px" }} src="/assets/correctimage-removebg-preview.webp" /> Choose from 70+ locations in UAE
                </li>
                <li className={handleClasses("my-1")} style={{ marginBottom: "2px" }}>
                  <img width={20} style={{ marginBottom: "5px" }} src="/assets/correctimage-removebg-preview.webp" /> Get the Best Offers & Discounts
                </li>
                <li className={handleClasses("my-1")} style={{ marginBottom: "2px" }}>
                  <img width={20} style={{ marginBottom: "5px" }} src="/assets/correctimage-removebg-preview.webp" /> Convenient Car PickUp & Drop
                </li>
                <li className={handleClasses("my-1")} style={{ marginBottom: "2px" }}>
                  <img width={20} style={{ marginBottom: "5px" }} src="/assets/correctimage-removebg-preview.webp" /> Make Payment with Apple Pay & Tabby
                </li>
                <li className={handleClasses("my-1")} style={{ marginBottom: "2px" }}>
                  <img width={20} style={{ marginBottom: "5px" }} src="/assets/correctimage-removebg-preview.webp" /> All services backed by OB Assure
                </li>
                <li className={handleClasses("my-1")} style={{ marginBottom: "2px" }}>
                  <img width={20} style={{ marginBottom: "5px" }} src="/assets/correctimage-removebg-preview.webp" /> Earn 1% OB CashPoints on all jobs
                </li>
                <li className={handleClasses("my-1")} style={{ marginBottom: "2px" }}>
                  <img width={20} style={{ marginBottom: "5px" }} src="/assets/correctimage-removebg-preview.webp" /> Maintain Full Service History
                </li>
              </ul>
            </div>
          </div>
          <div className={`col-md-4 ${handleClasses("col-md-4")}`}>
            <div className={handleClasses("card-why")}>
              <div className="card-banner-block-up">

                <div className="inro-txt">
                  <span>Introducing</span>
                </div>
                {/* <div className={handleClasses("why-lvl-11")}>
                <img src="assets/home/why3.webp" alt="why" />
              </div> */}
                <div className={handleClasses("why-lvl-2")}>
                  <img src="/assets/images/level-up.webp" alt="level-up" />
                </div>
                <div className={handleClasses("why-lvl-3")}>
                  <h5 className="mt-3 prmtxt  text-center font-weight-bold">
                    <label className="orng-txt">Premium</label>  <small>Annual Subscription</small>
                    <span className="">
                      Enhance Your OB Experience
                    </span>
                  </h5>
                  <p className="text-white text-center custom-prz">


                    <label> With Added Benefits </label>
                    <div className="prz-lvup"> For Only<span className="font-weight-bold">299</span> <small>AED</small></div>
                  </p>
                </div>
                <div className={handleClasses("why-lvl-4 cstlv-slider")}>
                  <div className={`slider-text-lup ${handleClasses("slider-text-lup")}`}>
                    <Slider {...settings}>
                      <div className={handleClasses("slide-lvup")}>
                        <div className={`d-flex ${handleClasses("d-flex")}`}>
                          <img src="assets/home/car-pickup.webp" alt="pickup" />
                          <span>Free Pickup Drop On All Services</span>
                        </div>
                      </div>
                      <div className={handleClasses("slide-lvup")}>
                        <div className={`d-flex ${handleClasses("d-flex")}`}>
                          <img src="assets/home/income.webp" alt="profit" />
                          <span>Earn Double OB CashPoints</span>
                        </div>
                      </div>
                      <div className={handleClasses("slide-lvup")}>
                        <div className={`d-flex ${handleClasses("d-flex")}`}>
                          <img src="assets/home/offer.webp" alt="offer" />
                          <span>Get Exclusive Offers</span>
                        </div>
                      </div>
                      <div className={handleClasses("slide-lvup")}>
                        <div className={`d-flex ${handleClasses("d-flex")}`}>
                          <img src="assets/home/calendar.webp" alt="validity" />
                          <span>12 Months Validity</span>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardSlide;