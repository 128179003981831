import { connect, useDispatch } from "react-redux";
import Layout from "../layouts/Layout";
import {
  getCoordinates,
  getVariants,
  getWorkshopsJourneyCategory,
  getWorkshopAmenities,
  getTopSellingJob,
  getModel,
} from "../redux/actions/apiActions";
import {
  getCarBrandModal,
  getLocationData,
  setAccessLocation,
} from "../redux/actions/globalAction";
import { getUserAuth, resetStore } from "../redux/actions/loginAction";
import { useEffect, useState } from "react";
import { clearLoginData } from "../redux/actions/loginAction";
import { Container } from "react-bootstrap";
import { fetchCustomerCart } from "../actions/homePage";
import CustomHome from "components/Home";
import AOS from "aos";
import { useBeforeunload } from "react-beforeunload";
import { useRouter } from "next/router";
import toast from "react-hot-toast";
import Head from "next/head";

const FashionOne = ({
  apiData,
  getCarBrandModal,
  globalData,
  getUserAuth,
  resetStore,
  loginData,
}) => {
  const [loop, setLoop] = useState(false);
  const [loop1, setLoop1] = useState(false);
  const [loop2, setLoop2] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showLocationItems, setShowLocationItems] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();

  const removeQueryParam = (paramName) => {
    const query = { ...router.query };
    delete query[paramName];
    router.push({ pathname: router.pathname, query: query }, undefined, {
      shallow: true,
    });
  };

  useEffect(() => {
    if (router && router?.query && router?.query?.SuccessMessage) {
      toast.dismiss();
      toast.success("Email Verified!");
      removeQueryParam("SuccessMessage");
    } else if (router && router?.query && router?.query?.ErrorMessage) {
      if (router.query.ErrorMessage == "Email Already Verified!") {
        toast.dismiss();
        toast.success("Email Already Verified!");
      } else {
        toast.dismiss();
        toast.error("Email Verification Failed!");
      }
      removeQueryParam("ErrorMessage");
    }
  }, [router, router.query]);

  useBeforeunload(async (event) => {
    let login_Data = JSON.parse(localStorage.getItem("login_data"));
    if (event.type !== "beforeunload" && !loginData.userAuth && !login_Data) {
      localStorage.clear();
      await resetStore();
    }
  });

  useEffect(() => {
    let cData = JSON.parse(localStorage.getItem("car_data"));
    let loginData = JSON.parse(localStorage.getItem("login_data"));
    let loginAccess = JSON.parse(localStorage.getItem("login_access"));
    if (cData != undefined && cData != null && cData != "" && loop == false) {
      setLoop(true);

      getCarBrandModal(
        cData.brand,
        cData.modal,
        cData.brandUrl,
        cData.modalUrl,
        cData.brandId,
        cData.modelId,
        cData.Slug
      );
    }
    if (
      loginData != undefined &&
      loginData != null &&
      loginData != "" &&
      loop1 == false
    ) {
      setLoop1(true);
      getUserAuth(loginData);
    }
    if (
      loginAccess != undefined &&
      loginAccess != null &&
      loginAccess != "" &&
      loop2 == false
    ) {
      setLoop2(true);
      getUserAuth(loginAccess);
    }
    if (AOS) {
      AOS?.init({
        delay: 400,
        duration: 800,
      });
    }
  }, [globalData]);

  useEffect(async () => {
    dispatch(fetchCustomerCart());
    localStorage.setItem("reload", 0);
  }, []);

  if (showMap && !showLocationItems) {
    setShowLocationItems(true);
  }

  return (
    <Layout
      navPositionClass="justify-content-center"
      carData={true}
      title={"Adding Trust in Car Services"}
      locationOn={false}
      askLocation={showLocationItems}
    >
      <Head>
        <meta property="og:title" content="Open Bonnet" />
        <meta
          property="og:description"
          content="Open Bonnet is the most trusted and largest car repair and Service center in UAE. Trust our High-quality service by efficient and experienced mechanics for all your automotive needs."
        />
      </Head>
      <Container fluid className="p-0 home-container custom-width-banner">
        <CustomHome apiData={apiData} />
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
    globalData: state.globalData,
    loginData: state.loginData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearLoginData: () => {
      dispatch(clearLoginData());
    },
    getCoordinates: (lat, lang, size) => {
      dispatch(getCoordinates(lat, lang, size));
    },
    getVariants: (id, mId) => {
      dispatch(getVariants(id, mId));
    },
    getWorkshopsJourneyCategory: (id) => {
      dispatch(getWorkshopsJourneyCategory(id));
    },
    getWorkshopAmenities: (id) => {
      dispatch(getWorkshopAmenities(id));
    },
    getTopSellingJob: (mId) => {
      dispatch(getTopSellingJob(mId));
    },
    getModel: (id) => {
      dispatch(getModel(id));
    },
    getUserAuth: (data) => {
      dispatch(getUserAuth(data));
    },
    setAccessLocation: (data) => {
      dispatch(setAccessLocation(data));
    },
    resetStore: (data) => {
      dispatch(resetStore(data));
    },
    getLocationData: (lat, lng, address) => {
      dispatch(getLocationData(lat, lng, address));
    },
    getCarBrandModal: (
      brand,
      modal,
      logoBrand,
      logoModal,
      brandId,
      modelId,
      slug = null
    ) => {
      dispatch(
        getCarBrandModal(
          brand,
          modal,
          logoBrand,
          logoModal,
          brandId,
          modelId,
          slug
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FashionOne);
