import { handleClassNames } from "lib/cssHelpers";
import React from "react";
import Style from "@assets/css/home.module.css";
import Slider from "react-slick";
import { useRouter } from "next/router";
import LazyLoad from "react-lazyload";
import { isMobile } from "react-device-detect";

const images = [
  { url: "/assets/why/Caraousal-1.webp" },
  { url: "/assets/why/Caraousal-2.webp" },
  { url: "/assets/why/Caraousal-3.webp" },
];

const CarSlideMobile = ({ setLoader, globalData }) => {
  const handleClasses = (classValue) => {
    return handleClassNames(classValue, Style);
  };

  const router = useRouter();

  const handleRequest = () => {
    setLoader(true);
    router.push(
      `/services/${globalData.locationData?.data?.EmirateAreaSlug || "dubai"
      }/mobile-mechanic`
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipe: false,
    slidesToShow: 1,
  };

  const settingsMain = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    slidesToShow: 1,
  };

  return (
    <section className={handleClasses("card-slide-block")}>
      <div className="container">
        {/* <div className="row"> */}
        <Slider {...settingsMain} className="car-slide-mobile">
          <div className={`col-md-4 p-1 ${handleClasses("col-md-4")}`}>
            <div className={handleClasses("card-why card-why-mobile")}>
              <div
                className="w-100 h-100 position-relative"
                style={{ cursor: "pointer" }}
              >
                <div className={handleClasses("why-crd-1-skin")}>
                  <div className={handleClasses("why-crd-2")}>
                    {!isMobile ? (
                      <img
                        alt="super-car"
                        src="assets/home/super-car-mob.webp"
                      />
                    ) : (
                      <img alt="super-car" src="assets/home/super-car-mob.webp" />
                    )}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setLoader(true);
                    router.push("/super-car");
                  }}
                  className={handleClasses("why-crd-title")}
                >
                  <h2 className="text-white sptxt-bloxk">
                    <span>Super</span> Cars
                  </h2>
                  <p className="text-white">
                    Your Premium Car Needs Specialized Care & Trusted Advice
                  </p>
                  <p className="mb-0 text-white">
                    <span style={{ textDecoration: "underline" }}>View More</span>{" "}
                    <i className="right-arrow-banner-home">
                      <img
                        style={{
                          cursor: "pointer",
                          width: "26px",
                          margin: "auto",
                        }}
                        src="assets/home/right-angle.webp"
                      />{" "}
                    </i>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-1 show-section-web">
            <div className={handleClasses("card-why card-why-mobile")}>
              <div className="mechanic-container-custom custom-adjust-screen">
                <div className="mob-mac-block">
                  <h2 className="heading-common-font-size main-new-heading">Mobile Mechanic</h2>
                  <p className="sub-heading-common-font-size">
                    Let Our Crew Reach Your Location For Selected Periodic
                    Services
                  </p>
                  <button
                    onClick={() => handleRequest()}
                    className="req-an-btn mt-1"
                  >
                    Request Now
                  </button>
                </div>
                <div className="mob-mac-img">
                  <img src="/assets/home/mob-car.webp" />
                </div>
                <i className="right-arrow-banner-home">
                  <img
                    style={{
                      cursor: "pointer",
                      width: "26px",
                      margin: "auto",
                      filter: "brightness(0.5)"
                    }}
                    src="assets/home/right-angle.webp"
                  />{" "}
                </i>
              </div>
            </div>
          </div>
          <div className={`col-md-4 p-1 ${handleClasses("col-md-4")}`}>
            <div className={handleClasses("card-why card-why-mobile")}>
              <div className="card-banner-block-up">
                <div className="inro-txt">
                  <span>Introducing</span>
                </div>
                {/* <div className={handleClasses("why-lvl-11")}>
            <img src="assets/home/why3.webp" alt="why" />
          </div> */}
                <div className={handleClasses("why-lvl-2")}>
                  <img src="/assets/images/level-up.webp" alt="level-up" />
                </div>
                <div className={handleClasses("why-lvl-3")}>
                  <h5 className="mt-3 prmtxt text-center font-weight-bold">
                    <label className="orng-txt">Premium</label>{" "}
                    <small>Annual Subscription</small>
                    <span className="">Enhance Your OB Experience</span>
                  </h5>
                  <p className="text-white text-center custom-prz mt-3">
                    <label> With Added Benefits </label>
                    <div className="prz-lvup">
                      {" "}
                      For Only<span className="font-weight-bold">299</span>{" "}
                      <small>AED</small>
                    </div>
                  </p>
                </div>
                <div className={handleClasses("why-lvl-4 cstlv-slider")}>
                  <div
                    className={`slider-text-lup ${handleClasses(
                      "slider-text-lup"
                    )}`}
                  >
                    <Slider {...settings} className="card-slide-level-up">
                      <div className={handleClasses("slide-lvup")}>
                        <div className={`d-flex ${handleClasses("d-flex")}`}>
                          <img src="assets/home/car-pickup.webp" alt="pickup" />
                          <span>Free Pickup Drop On All Services</span>
                        </div>
                      </div>
                      <div className={handleClasses("slide-lvup")}>
                        <div className={`d-flex ${handleClasses("d-flex")}`}>
                          <img src="assets/home/income.webp" alt="profit" />
                          <span>Earn Double OB CashPoints</span>
                        </div>
                      </div>
                      <div className={handleClasses("slide-lvup")}>
                        <div className={`d-flex ${handleClasses("d-flex")}`}>
                          <img src="assets/home/offer.webp" alt="offer" />
                          <span>Get Exclusive Offers</span>
                        </div>
                      </div>
                      <div className={handleClasses("slide-lvup")}>
                        <div className={`d-flex ${handleClasses("d-flex")}`}>
                          <img src="assets/home/calendar.webp" alt="validity" />
                          <span>12 Months Validity</span>
                        </div>
                      </div>
                    </Slider>
                  </div>

                </div>
                <i className="right-arrow-banner-home">
                  <img
                    style={{
                      cursor: "pointer",
                      width: "26px",
                      margin: "auto",
                    }}
                    src="assets/home/right-angle.webp"
                  />{" "}
                </i>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      {/* </div> */}
    </section>
  );
};

export default CarSlideMobile;
